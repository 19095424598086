<template>
  <loading-dialog :loading="$apollo.loading">
    <mf-card-container title="Importação" description="Aqui são realizadas as configurações relativas à conexão com o ambiente do cliente.">
      <template #headers>
        <v-row no-gutters justify="end">
          <v-col>
            <mf-button label="Adicionar servidor" icon="dns" @click="dialog = true"></mf-button>
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="openPanels" class="mt-3" focusable>
            <importing-flags :is-deploying="isDeployingOrTest" :import-configuration="importConfiguration" :account-id="accountId" @cancel="cancelAndRestore" />
            <importing-first-load
              :is-deploying="isDeployingOrTest"
              :db_name="db_name"
              :integration-type="integration_type"
              :import-configuration="importConfiguration"
              :account-id="accountId"
              @cancel="cancelAndRestore"
            />
            <v-expansion-panel v-for="(server, index) of servers" :key="index" class="mt-3">
              <v-expansion-panel-header>
                <span class="card-title"
                  >Server {{ getSafe(() => `${server.connection.host}:${server.connection.port} - ${server.connection.database}`) }}</span
                >
              </v-expansion-panel-header>
              <importing-servers
                :is-deploying="isDeployingOrTest"
                :is-hybrid="isHybrid"
                :query-keys="queryKeys"
                :account-id="accountId"
                :servers="servers"
                :server="server"
                :index="index"
                :erp="erp"
                @cancel="cancelAndRestore"
                @refresh="refresh"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </mf-card-container>
    <server-modal :is-deploying="isDeployingOrTest" :dialog.sync="dialog" :server="newServer" @close="cancelAndRestore" @save="addServer" />
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_ADD_CONNECTION_SERVER } from '@/modules/accounts/graphql'
import _ from 'lodash'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    ImportingFlags: () => import('@/modules/accounts/components/edit/ImportingFlags'),
    ImportingFirstLoad: () => import('@/modules/accounts/components/edit/ImportingFirstLoad'),
    ImportingServers: () => import('@/modules/accounts/components/edit/ImportingServers'),
    ServerModal: () => import('@/modules/accounts/partials/ServerModal')
  },
  data: () => ({
    dialog: false,
    accountId: '',
    servers: [],
    openPanels: [],
    erp: {},
    importConfiguration: {
      released: false,
      active: false,
      realtime: false,
      first_load: {
        parallelism: 1,
        start_date: '',
        end_date: ''
      },
      selectedScale: 'months',
      scaleAmount: 1
    },
    newServer: {
      erpServers: [],
      pos: true,
      integrator: '',
      connection: {
        database: '',
        host: '0.0.0.0',
        password: '',
        user: '',
        port: 0,
        charset: ''
      }
    },
    db_name: '',
    isDeployingOrTest: false,
    isHybrid: false,
    queryKeys: [],
    integration_type: ''
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.newServer.erpServers = adminAccount?.erp_integrators
        this.erp = adminAccount?.erp

        // Flags
        this.importConfiguration.released = adminAccount?.import_configuration.released
        this.importConfiguration.active = adminAccount?.import_configuration.active
        this.importConfiguration.realtime = adminAccount?.import_configuration.realtime

        // First load
        this.importConfiguration.first_load.parallelism = adminAccount?.import_configuration.first_load?.parallelism
        this.importConfiguration.first_load.start_date = adminAccount?.import_configuration.first_load?.start_date
        this.importConfiguration.first_load.end_date = adminAccount?.import_configuration.first_load?.end_date
        const [scale, amount] = Object.entries(adminAccount?.import_configuration.first_load?.scale || {})[0]
        this.importConfiguration.selectedScale = scale
        this.importConfiguration.scaleAmount = amount
        this.isDeployingOrTest = adminAccount?.status_account?.deploying || adminAccount?.status_account?.test

        this.db_name = adminAccount?.db_name

        // Servers
        this.servers = _.cloneDeep(adminAccount?.import_configuration.servers)
        this.isHybrid = adminAccount?.integration_type === 'hybrid'
        this.integration_type = adminAccount?.integration_type

        const queries = []
        this.servers.map(server => {
          if (server?.configuration?.sqls) Object.keys(server?.configuration?.sqls).forEach(query => queries?.push(query))
        })
        this.queryKeys = queries
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async addServer() {
      const variables = {
        id: this.accountId,
        pos: this.newServer.pos,
        integrator: this.newServer.integrator,
        host: this.newServer.connection.host,
        port: this.newServer.connection.port.toString(),
        password: this.newServer.connection.password,
        user: this.newServer.connection.user,
        database: this.newServer.connection.database,
        charset: this.newServer.connection.charset
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ADD_CONNECTION_SERVER,
          variables
        })
        this.$alert({
          alert_message: 'Nova conexão inserida com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.cancelAndRestore()
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao inserir nova conexão',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    cancelAndRestore() {
      this.clearNewServer()
      this.$apollo.queries.adminAccount.refetch()
    },
    clearNewServer() {
      this.dialog = false
      this.newServer.pos = true
      this.newServer.connection.database = ''
      this.newServer.connection.pos = false
      this.newServer.connection.host = '0.0.0.0'
      this.newServer.connection.password = ''
      this.newServer.connection.user = ''
      this.newServer.connection.port = 0
      this.newServer.connection.charset = ''
    },
    refresh() {
      this.$apollo.queries.adminAccount.refetch()
    }
  }
}
</script>
<style lang="scss" scoped>
.v-btn {
  margin: 3px;
}
</style>
